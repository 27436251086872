
.label-custom{
	text-align: left;
	font-family: Arial, Helvetica, sans-serif;
	color:#000000e0;
	font-size:17px;
	font-weight: bold;
}

.label-blank{
	text-align: left;
	font-family: Arial, serif;
	color: white;
	font-size: 80%;
}


.titletext{
	text-align: center;
	font-family: Arial, serif;
	color: black;
	font-size: 120%;
}

.brandname{
	vertical-align: center;
}
.navbar{
	padding:0;
}
/* fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
    box-shadow:  0px 0px 0px 0px #000;
}

legend.scheduler-border {
	font-size: 1.2em !important;
	font-weight: bold !important;
	text-align: left !important;
	width:auto;
	padding:0 10px;
	border-bottom:none;
}
 */
 
/* Jyoti css starts here*/

/*css for table*/
.table-responsive-stack tr ,
.table-responsive-budget tr{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
		-ms-flex-direction: row;
			flex-direction: row;
  }  
  .table-responsive-stack td,
  .table-responsive-stack thead {
	 display: block;
	 -ms-flex: 1;
	  flex: 1;
  /*      
	 flex-grow | flex-shrink | flex-basis   */
  }
  .table-responsive-budget td,
  .table-responsive-budget thead{
	display: block;
	-ms-flex: 1 1 auto;
	 flex: 1 1 auto;
  }
  .roiboxing{
	width: 25%;
}
.modal-sm{
	max-width: 300px;
	margin: 1.75rem auto;
}
  
  .table-responsive-stack .table-responsive-stack-thead {
	 font-weight: bold;
  }
  
   @media screen and (max-width: 768px) {
	 .table-responsive-stack tr {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
			-ms-flex-direction: column;
				flex-direction: column;
		border-bottom: 3px solid #ccc;
		display:block;  
		
	 }
	 /*  IE9 FIX   */
	.table-responsive-stack td {
	   float: left\9; 
		width:100%;
		text-align: left;
	 } 
	 .roiboxing{
		width: 50%;
	}
	.roilinebreak{
		width: 50%;
	}
	.modal-sm{
		max-width: 300px;
		margin: 1.75rem auto;
	}
  } 
@media screen and (max-width: 500px) {  
	.table-responsive-budget tr {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
			-ms-flex-direction: column;
				flex-direction: column;
		border-bottom: 3px solid #ccc;
		display:block; 
		width:100%; 
		
	 }
	 .table-responsive-budget td {
    	float: left\9; 
		 width:100%;
		 text-align: left;
	  } 
	 .roiboxing{
		width: 100%;
	}
	.roilinebreak{
	  width: 100%;
	}
	
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}
 }

  
 /*css for table end*/ 

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
  }
  [className*="col-md-"] {
	width: 100%;
  }
/*border style for all text input*/
input[type="text"]:focus, textarea:focus 
{
  border-color: rgb(50, 138, 209);
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  outline: 0 none;
}
.profilealignment{
	position: absolute;
	right: 40%;

}
.logo{
	color:White;
	background-color: rgb(50, 138, 209);
	text-align: center;
}
.dropdown-menu{
	text-align: left;
	top:5px !important;
	left:4px !important;
}
#textfontsignin{
	color: rgb(50, 138, 209);
	
}
.buttonadjust{
	width: 77%;
}
.textblue{
	color: rgb(50, 138, 209);
	
}
.textgreen{
	color:rgb(3, 129, 3);
	text-align: center;
}
.brdcolor input{
border-color: rgb(50, 138, 209);
}
.brdcolor select{
	border-color: rgb(50, 138, 209);
}
.brdcolor textarea{
	border-color: rgb(50, 138, 209);
}
.brdcolor input[type=radio]{
	border-color: rgb(50, 138, 209);
}
.errormsg{
	color:red;
	font-size: 13px;
}
.wlcmcolor{
	color:gray;
	font-size: 16px;
}
.addevent{
	height: 200px;
	width: 300px;
	padding:1px 5px;
	box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	border:solid whitesmoke;
	border-radius: 5px;	
}
.addevent:hover {
	box-shadow: 0 8px 18px 0 rgba(0,0,0,0.2);
}
.tablehover  hover{
	background-color: #ddd;
}
table tr span { 
	opacity:0; 
	float: right;
}
table tr:hover span {
	opacity:1;
}

.notestyle{
	background-color: #ddd;
	font-size: small;
	border: none;
	color: black;
	padding: 8px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	margin: 10px 10px;
	cursor: pointer;
	border-radius: 0px 45px 45px 0px;
}
.boxstyle{
margin-left: 2%;
}
.eventalign{
text-align: left;
}
.dollar{
	position: relative;
	border: 0px inset #ccc;
	}
.dollar input{
	padding-left:15px;
	border-width: 0px  0px 1px 0px;
	border-color: rgb(143, 141, 141);
	text-indent: 15px;
	text-align: right;
	width: 170px;
	}
.dollar:before {
	position: absolute;
	content:"$";
	text-align: center;
	left:5px;
}
.budgettotal{
	text-align:right;
}
.dollartotal{
	width: 90%;
 text-align:right;	
}
.dollarroiinput{
	width: 150px;
	text-align:right;	
}
.thead{
	background-color: rgb(231, 227, 227);
	font-size:15px ;
	font-weight: bold;
	
}
.pluscolor{
	color:rgb(16, 135, 253);
	font-size:20px ;
	text-align: center;
}

.borderstyle input{
   border-style: solid;
   border-width: 0px  0px 2px 0px;
   border-color: rgb(143, 141, 141);
   text-align: right;
   width: 150px;

}

.invisiblefollow input{
	border: solid;
	border-color: transparent;
	width: 250px;
	padding : 0px;
	margin: 0px;
}
.bobjective input{
	border-style: solid;
	border-width:1px;
	border-color: rgb(143, 141, 141);
	width: 250px;	
}
.borderstyleinvisible input{
	border: none;
	border-color: transparent;
}
.invisibleborder{
	border: none;
	border-color: transparent;
}
.colorborder{
	border-style: solid;
	border-width:1px;
	border-color:rgb(50, 138, 209);
}
.deletebox span{
	opacity: 0;
}
.deletebox:hover span{
	opacity: 1;
}
.objboxstyle{
	border: solid;
	border-width: 1px;
	border-color:rgb(50, 138, 209);
	margin-bottom: 10px; 
}
.btnstyleActive{
	border: none;
	background-color:lightgrey;
	color:#5F6368;
	text-align: left;
	padding:5px;
	text-decoration: none;
	font-size: 15px;
	border-radius: 0px 45px 45px 0px;
	cursor: pointer;
	}
.btnstyle{
	border: none;
	background-color: none;
	color:#5F6368;
	padding:5px;
	text-align: left;
	text-decoration: none;
	font-size: 15px;
	border-radius: 0px 45px 45px 0px;
	cursor: pointer;
}		
.btnstyle:hover {
	border: none;	
	border-color: none;
	color:rgb(39, 56, 218);
	background-color:rgb(240, 236, 236);
} 
.btnstyle:focus{
		background: rgb(231, 227, 227);
}
.circleBtn {
	width: 50px; 
	height: 50px; 
	padding: 7px 10.7px; 
	border-radius: 25px; 
	font-size: 12px; 
	text-align: center; 
	background-color:rgb(200, 202, 204);
	color: rgb(15, 13, 13);
	text-decoration: none;
    margin: 10px;

 }
   /* Mark the active step: */
.circleBtnActive {
	width: 50px; 
	height: 50px; 
	padding: 7px 10.7px; 
	border-radius: 25px; 
	font-size: 12px; 
	text-align: center;  
	background-color:rgb(16, 135, 253);
	border:none;
	color:white;
	text-decoration: none;
    margin: 10px;
	
 }
.textActive{
    color:rgb(16, 135, 253);
	text-align: left;
	text-decoration: none;
	cursor: pointer;	
 }
 .boxstyle1{
	height: 200px;
	width: 200px;
	padding:1px 5px;
	box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	border:solid whitesmoke;
	border-radius: px;
	margin-left: 70px;
	margin-bottom: 20px;
  }
  .boxstyle3{
	height: 200px;
	width: 470px;
	padding:1px 5px;
	box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	border:solid whitesmoke;
	border-radius: px;
	margin-left: 70px;
	margin-bottom: 20px;
  }
  .boxstyle2{
	height: 640px;
	width: 300px;
	padding:1px 5px;
	box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	border:solid whitesmoke;
	border-radius: 5px;
	
	
  }
	  
	  /* Mark the steps that are finished and valid: */

.sucess
{
	margin-top:80px;
	padding-left: 70px;
	padding-right: 70px;
	color:rgb(50, 138, 209);
	height: 5px;
	text-align: center;
	font-size: 25px;

}
.btnNormal{
	background-color: none;
}

.red-border {
	border-color:red !important;
}
.cursorcolor{
	cursor: pointer;
	color:rgb(50, 138, 209);
}



/*Jyoti css ends */